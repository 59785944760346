/* Todos os elementos */

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto' sans-serif;
}

html, 
body,
#root {
  background: #efefef;
  height: 100%;
}