.container{
    width: 100%;
    max-width: 1900px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    flex-wrap: wrap;
    padding: 0;
}

.min-height{
    min-height: 75%;
}

.start{
    justify-content: flex-start;
}

.column{
    flex-direction: column;
    justify-content: flex-start;
}