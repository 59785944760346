.home_clt{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto 0;
    background-color: white;
}

/* ----------- VIDEO -------------*/
.home_video{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

video {
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
}

.btn_sales {
    position: absolute;
    top: 20%;
    left: 84%;
    margin: auto;
    cursor: pointer;
    outline: none;
    font-size: 15px;
    padding: 10px 10px;
    border-radius: 5px;
    background-color: transparent;
    transition: box-shadow 0.1s;
    color: black;
    border: 1px solid white;
}

.btn_sales:hover{
    border: 4px solid white;
    color: black;
    box-shadow: 0px 0px 35px white, 0px 0px 15px white inset;
    text-shadow: 0px 0px 10px white;
}
.btn_sales a{
    text-decoration: none;
    color: white;
}

@media screen and (max-width: 892px){

    .btn_sales{
        position: absolute;
        top: 7%;
        left: 73%;
        cursor: pointer;
        outline: none;
        font-size: 8px;
        padding: 5px 10px;
        border-radius: 4px;
        background: none;
        transition: box-shadow 0.1s;
        color: white;
        border: 1px solid white;
    }

    .btn_sales:hover{
        border: 4px solid white;
        color: white;
        box-shadow: 0px 0px 35px white, 0px 0px 15px white inset;
        text-shadow: 0px 0px 10px white;
    }
}
 #section{
    background-color: black;
}

/* CAROUSEL */
.carousel-item {
    height: 100vh;
    min-height: 300px;
    background: no-repeat scroll center scroll;
    -webkit-background-size: cover;
    background-size: cover;
}
.carousel-item::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0;
}
.carousel-caption{
    bottom: 180px;
    padding-left: 100px;
    padding-right: 100px;
}
.carousel-caption h5{
    font-size: 60px;
    font-weight: 700;
}
.carousel-caption p{
    font-size: 18px;
    top: 2rem;
}
.slider-btn{
    margin-top: 30px;
    text-decoration: none;
}
.slider-btn .btn{
    background-color: #FF2800;
    color: white;
    border-bottom: 0;
    padding: 0.8rem 1.1rem;
    font-size: 1rem;
    margin-right: 15px;
    text-decoration: none;
}
.slider-btn .btn-2{
    color: #111010;
    background-color: white;
    text-decoration: none;
}



/* ------------------------------------*/
.home_voluntary{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgb(179, 179, 179);
    height: 80vh;
    gap: 14px;
    padding: 34px 55px 64px 14px;
}

/* NEWS */
.section.is-link.py-6 {
    background-color: black;
}
.section.is-link.py-6 h1{
    text-align: center;
    margin-top: 1rem;
    font-size: 45px;
    color: white;
}
.section.is-link.py-6 h2{
    text-align: center;
    margin-top: 2rem;
    font-size: 30px;
    color: white;
    font-weight: 100;
}
.section.is-link.py-6 p{
    font-size: 15px;
    margin: 0 55px;
    color: white;
}
.section.is-link.py-6 img{
    width: 30rem;
    height: 38rem;
}
.section.is-link.py-6 button{
    text-align: center;
    left: 33%;
    margin-right: 10px;
    margin-top: 4rem;
    padding: 3px 8px 3px 8px;
    border-radius: 10px;
    background-color: black;
    transition-duration: ease background-color 250ms;
    &:hover {
        background-color: rgb(255, 214, 51);
    }
}


/* CULTOS */
.section.is-link.py-2 {
    background-color: white;
}
.section.is-link.py-2 h1{
    text-align: center;
    margin-top: 1rem;
    font-size: 45px;
    color: black;
}
.section.is-link.py-2 h2{
    text-align: center;
    font-size: 25px;
    margin: auto;
    margin-left: 0rem;
    margin-top: 1.5rem;
    color: black;
}
.section.is-link.py-2 img{
    max-width: 18rem;
    max-height: 25rem;
    top: 2.5rem;
    left: 3rem;
    box-sizing: border-box;
    border-radius: 10px;
}
.section.is-link.py-2 button{
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    margin: 0 4px;
    left: 35%;
    margin-top: 2.5rem;
    background-color: black;
    transition-duration: ease background-color 250ms;
    &:hover {
        background-color: rgb(255, 214, 51);
    }
}
.section.is-link.py-2 a{
    text-decoration: none;
    color: white;
}

/* Gcs */
.section.is-link.py-1{
    background-color: black;

}
.section.is-link.py-1 h1{
    font-size: 10rem;
    margin-top: -10rem;
    color: white;
}

.section.is-link.py-1 h2{
    color: white;
}

.section.is-link.py-1{
    height: 55rem;
}
.section.is-link.py-1 img{
    width: 50rem;
    height: 30rem;
    left: -3rem;
    top: 14rem;
}
.section.is-link.py-1 button{
    text-align: center;
    font-size: 15px;
    left: 0rem;
    top: 0rem;
    background-color: black;
    transition-duration: ease background-color 250ms;
    &:hover {
        background-color: rgb(255, 214, 51);
    }
}
.section.is-link.py-1 a{
    text-decoration: none;
    color: white;
}

@media screen and (min-width:435px) and (max-width:545px){
    .carousel-item {
        height: 50%;
    }
    .carousel-item img{
        height: 52vh;
    }
    .section.is-link.py-2 {
        background-color: white;
        height: 150vh;
    }
    
    .section.is-link.py-2 img{
        width: 18rem;
        height: 22rem;
        left: 5rem;
        top: 0rem;
    }
    .section.is-link.py-2 button{
        font-size: 20px;
        left: 25%;
    }
    .section.is-link.py-1{
        background-color: black;
    }
    .section.is-link.py-1 h1{
        font-size: 10rem;
        margin-top: -10rem;
        text-align: center;
        color: white;
    }
    
    .section.is-link.py-1 h2{
        text-align: center;
        color: white;
    }
    
    .section.is-link.py-1{
        height: 55rem;
    }
    .section.is-link.py-1 img{
        width: 100%;
        height: 60%;
        left: 0rem;
        top: 5rem;
    }
    .section.is-link.py-1 button{
        text-align: center;
        font-size: 15px;
        left: 7rem;
        top: 0rem;
    }

}


/* MOBILE */
@media screen and (max-width: 430px){
    .carousel-item {
        height: 50%;
    }
    .carousel-item img{
        height: 35vh;
    }
    
    .section.is-link.py-6 {
        background-color: black;
        height: 58rem;
        margin-top: -1rem;
    }
    .section.is-link.py-6 h1{
        font-size: 30px;
    }
    .section.is-link.py-6 h2{
        font-size: 25px;
    }
    .section.is-link.py-6 p{
        font-size: 15px;
        margin: auto 11px;
    }
    .section.is-link.py-6 img{
        width: 20rem;
        height: 22rem;
        left: 2rem;
    }
    .section.is-link.py-6 button{
        text-align: center;
        left: 6.5rem;
        margin-right: 10px;
        top: -10rem;
    }
}

@media screen and (max-width: 430px) {
    .section.is-link.py-2 {
        background-color: white;
        height: 95vh;
    }
    .section.is-link.py-2 h1{
        font-size: 30px;
    }
    .section.is-link.py-2 h2{
        text-align: center;
        font-size: 20px;
        margin: auto;
        margin-left: 0rem;
        margin-top: 1.5rem;
        color: black;
    }
    .section.is-link.py-2 img{
        width: 18rem;
        height: 22rem;
        left: 2.5rem;
        top: 0rem;
    }
    .section.is-link.py-2 button{
        font-size: 20px;
        left: 20%;
    }
}
@media screen and (max-width: 430px){
    .section.is-link.py-1{
        background-color: black;
    }
    .section.is-link.py-1 h1{
        font-size: 10rem;
        margin-top: -9rem;
        text-align: center;
        color: white;
    }
    
    .section.is-link.py-1 h2{
        text-align: center;
        color: white;
    }
    
    .section.is-link.py-1{
        height: 55rem;
    }
    .section.is-link.py-1 img{
        width: 100%;
        height: 60%;
        left: 0rem;
        top: 5rem;
    }
    .section.is-link.py-1 button{
        text-align: center;
        font-size: 15px;
        left: 5rem;
        top: 0rem;
        background-color: black;
        transition-duration: ease background-color 250ms;
        &:hover {
            background-color: rgb(255, 214, 51);
        }
    }
}

/* MOBILE 390 */
@media screen and (max-width: 390px){
    .carousel-item {
        height: 50%;
    }
    .carousel-item img{
        height: 35vh;
    }
    .section.is-link.py-6 p{
        font-size: 12px;
        margin: auto 22px;
    }
    
    .section.is-link.py-6 img{
        width: 20rem;
        height: 22rem;
        left: 1rem;
    }
    .section.is-link.py-6 button{
        text-align: center;
        left: 6rem;
        margin-right: 10px;
        top: -1rem;
    }
}

@media screen and (max-width: 390px) {
    .section.is-link.py-2 {
        background-color: white;
        height: 100vh;
    }
    .section.is-link.py-2 button{
        left: 4rem;
    }
    .section.is-link.py-2 img{
        width: 14rem;
        height: 18rem;
        left: 4rem;
        top: 0rem;
    }

}


/* MOBILE 414 */
@media screen and ( min-width:400px) and (max-width: 414px){
    .carousel-item {
        height: 50%;
    }
    .carousel-item img{
        height: 32vh;
    }
    .section.is-link.py-6 img{
        width: 20rem;
        height: 22rem;
        left: 1rem;
    }
    .section.is-link.py-6 button{
        text-align: center;
        left: 6rem;
        margin-right: 10px;
        top: -1rem;
    }
}

@media screen and (max-width: 390px) {
    .section.is-link.py-2 button{
        left: 4rem;
    }
    .section.is-link.py-2 img{
        width: 14rem;
        height: 18rem;
        left: 4rem;
        top: 0rem;
    }

}
@media screen and (max-width: 390px){
    .section.is-link.py-1{
        background-color: black;
        height: 95vh;
    }
    .section.is-link.py-1 h1{
        font-size: 8rem;
        margin-top: -7rem;
    }

    .section.is-link.py-1 img{
        width: 100%;
        height: 60%;
        left: 0rem;
        top: 6rem;
    }
    .section.is-link.py-1 button{
        text-align: center;
        font-size: 15px;
        left: 4rem;
        top: 0rem;
        background-color: black;
        transition-duration: ease background-color 250ms;
        &:hover {
            background-color: rgb(255, 214, 51);
        }
    }
}

@media screen and (max-width: 375px){
    .btn_sales {
        position: absolute;
        top: 9%;
        left: 72%;
    }    
    .carousel-item {
        height: 30vh;
    }
    .carousel-item img{
        height: 43vh;
    }
    .section.is-link.py-1{
        background-color: black;
        height: 120vh;
    }
    .section.is-link.py-1 h1{
        font-size: 8rem;
        margin-top: -7rem;
    }

    .section.is-link.py-1 img{
        width: 100%;
        height: 60%;
        left: 0rem;
        top: 6rem;
    }
    .section.is-link.py-1 button{
        left: 3rem;
    }
    .section.is-link.py-2 {
        background-color: white;
        height: 100%;
    }
    .section.is-link.py-2 h1{
        font-size: 30px;
    }
    .section.is-link.py-2 h2{
        text-align: center;
        font-size: 20px;
        margin: auto;
        margin-left: 0rem;
        margin-top: 1.5rem;
        color: black;
    }
    .section.is-link.py-2 img{
        width: 18rem;
        height: 22rem;
        left: 1.5rem;
        top: 0rem;
    }
    .section.is-link.py-2 button{
        font-size: 20px;
        left: 3.5rem;
    }
    
    .section.is-link.py-6 img{
        width: 20rem;
        height: 22rem;
        left: 0.5rem;
    }
    .section.is-link.py-6 button{
        text-align: center;
        left: 6rem;
        margin-right: 10px;
        top: -1rem;
    }
}