.navbar{
    display: block;
    width: 100%;
    justify-content: space-between ;
    align-items: center;
    background-color: rgb(0, 0, 0);
    padding: 0.5em;
}

.navbar img{
    position: relative;
    left: 80%;
}

.list {
    display: flex;
    list-style: none; 
    align-items: center;
}

.item {
    margin-right: 1em;
    margin-top: 0%;
}

.item a {
    color: white;
    text-decoration: none;
}

.tem a:hover {
    color: #FFBB33;
}


@media screen and (max-width: 892px){
    .navbar{
        display: flex;
        width: 100%;
        justify-content: space-between ;
        align-items: center;
        background-color: rgb(0, 0, 0);
        padding: 0.5em;
    }

    .list {
        display: flex;
        list-style: none; 
        align-items: center;
        margin-left: 5%;
    }
    
    .item {
        margin-right: 1.6em;
        margin-top: 0%;
    }
    
    .item a {
        color: white;
        text-decoration: none;
        font-size: 9px;
        
    }
    
    .tem a:hover {
        color: #FFBB33;
    }

    .navbar img{
        display: none;
    }
}

@media screen and (max-width: 574px){
    .navbar{
        display: flex;
        width: 100%;
        justify-content: space-between ;
        align-items: center;
        background-color: rgb(0, 0, 0);
        padding: 0.5em;

    }

    .list {
        display: flex;
        list-style: none; 
        align-items: center;
        margin-left: 5%;
    }
    
    .item {
        margin-left: 0rem;
        margin-top: 0%;
    }
    
    .item a {
        color: white;
        text-decoration: none;
        font-size: 9px;
    }
    
    .tem a:hover {
        color: #FFBB33;
    }

    .navbar img{
        display: none;
        width: 50%;
        margin-left: -40%
    }
}

@media screen and (max-width: 430px){
    .navbar{
        display: flex;
        width: 100%;
        justify-content: space-between ;
        align-items: center;
        background-color: rgb(0, 0, 0);
        padding: 0.5em;

    }

    .list {
        display: flex;
        list-style: none; 
        align-items: center;
        margin-left: 5%;
    }
    
    .item {
        margin-right: 1rem;
        margin-top: 0%;

    }
    
    .item a {
        color: white;
        text-decoration: none;
        font-size: 9px;
    }
    
}

@media screen and (max-width: 390px){
    .navbar{
        display: flex;
        width: 100%;
        justify-content: space-between ;
        align-items: center;
        background-color: rgb(0, 0, 0);
        padding: 0.5em;
    }

    .list {
        display: flex;
        list-style: none; 
        align-items: center;
    }
    
    .item {
        margin-right: 13px;
        margin-top: 0%;
    }
    
    .item a {
        color: white;
        text-decoration: none;
        font-size: 9px;
    }
}

@media screen and (max-width: 375px){
    .navbar{
        display: flex;
        width: 100%;
        justify-content: space-between ;
        align-items: center;
        background-color: rgb(0, 0, 0);
        padding: 0.5em;
    }

    .list {
        display: flex;
        list-style: none; 
        align-items: center;
    }
    
    .item {
        margin-right: 10px;
        margin-top: 0%;
    }
    
    .item a {
        color: white;
        text-decoration: none;
        font-size: 9px;
        
    }
    
    .tem a:hover {
        color: #FFBB33;
    }

    .navbar img{
        display: none;
    }
}