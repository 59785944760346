.quem_somos{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    background-color: black;
}

.heading{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 15px auto;
}

.heading h1{
    font-size: 50px;
    color: #f2f2f2;
    margin-bottom: 25px;
    position: relative;
}


.heading h1::after{
    content: "";
    position:absolute;
    width: 100%;
    height: 4px;
    display: block;
    margin: 0 auto;
    background-color: #f7da1b;
}

.container{
    width: 90%;
    margin: 0 auto;
    padding: 10px 20px;
}

.about{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.about_image{
    flex: 1;
    margin-right: 40px;
    overflow: hidden;
}

.about_image img{
    max-width: 60%;
    height: auto;
    display: block;
    transition: 0.5s ease;
}

.about_image:hover img{
    transform: scale(1.2) ;
}

.about_content{
    flex: 1;
}

.about_content p{
    font-size: 18px;
    line-height: 1.5;
    color: #f2f2f2;
}

@media screen and (max-width:768px) {
    .heading{
        padding: 0px 20px;
    }

    .heading h1{
        font-size: 36px;
    }

    .container{
        padding: 0px;
    }

    .about{
        padding: 20px;
        flex-direction: column;
    }

    .about_image{
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .about_image img{
        margin-left: 20%;
    }

    .about_content p{
        padding: 0px;
        font-size: 16px;
    }
}